.title{
    font-family: "Assistant";
    font-size: 3.5rem;
    text-align: center;
    margin:0 auto;
    direction: rtl;
    color:rgb(38, 19, 6);
    text-shadow: 2px 2px 3px rgb(38, 19, 6);
}
.center{
    display: flex;
    justify-content: center;
    margin:2% auto;
    width: 20%;
}
.image{
    width:100%;
    object-fit: contain;
    box-shadow: 0 0 5px 1px #555;
    border-radius: 20px;
}
.description{
    font-family: "AssistantR";
    font-size: 1.4rem;
    text-align: center;
    margin:1% auto;
    width:50%;
    color:rgb(38, 19, 6);
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
@media only screen and (max-width: 350px) {
    .title{
        font-family: "Assistant";
        font-size: 2.5rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
        color:rgb(38, 19, 6);
        text-shadow: 2px 2px 3px rgba(38, 19, 6, 0.426);
    }
    .description{
        font-family: "AssistantR";
        font-size: 1.1rem;
        text-align: center;
        margin:1% auto;
        width:95%;
        color:rgb(38, 19, 6);
    }
    .center{
        display: flex;
        justify-content: center;
        margin:2% auto;
        width: 80%;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title{
        font-family: "Assistant";
        font-size: 2.8rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
        color:rgb(38, 19, 6);
        text-shadow: 2px 2px 3px rgba(38, 19, 6, 0.426);
    }
    .center{
        display: flex;
        justify-content: center;
        margin:2% auto;
        width: 75%;
    }
    .description{
        font-family: "AssistantR";
        font-size: 1.2rem;
        text-align: center;
        margin:1% auto;
        width:95%;
        color:rgb(38, 19, 6);
    }
}
@media only screen and (min-width: 450px) and (max-width: 650px) {
    .title{
        font-family: "Assistant";
        font-size: 3rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
        color:rgb(38, 19, 6);
        text-shadow: 2px 2px 3px rgba(38, 19, 6, 0.426);
    }
    .center{
        display: flex;
        justify-content: center;
        margin:2% auto;
        width: 50%;
    }
    .description{
        font-family: "AssistantR";
        font-size: 1.2rem;
        text-align: center;
        margin:1% auto;
        width:85%;
        color:rgb(38, 19, 6);
    }
}
@media only screen and (min-width: 650px) and (max-width: 850px) {
    .center{
        display: flex;
        justify-content: center;
        margin:2% auto;
        width: 40%;
    } 
}
@media only screen and (min-width: 850px) and (max-width: 1050px) {
    .center{
        display: flex;
        justify-content: center;
        margin:2% auto;
        width: 30%;
    } 
}