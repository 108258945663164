.box{
 margin:4% auto;
width:300px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height:200px;

background: linear-gradient(180deg, #FFEFB8 0%, #B56D39 100%);
box-shadow: 1px 1px 10px 1px #452b1987;
border-radius: 50px;
}
.description{
    font-family: "AssistantR";
    font-size: 1.2rem;
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:90%;
    color:rgb(38, 19, 6);
}
.icon{
    width:30%;
    margin:1% auto;
justify-content: center;
}